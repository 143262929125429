import React, { useState, useEffect } from 'react';
import '../css/offerings.css';
import Heading from './heading';
import client from '../client/contentfulClient';

const Offerings = () => {
    const [visible, setVisible] = useState(false);
    const [desc, setDesc] = useState('');
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        setTimeout(() => setVisible(true), 10); 
        client.getEntry('5hOYnlXbV9FFDfe1qwtNio')
            .then(resp => {
                setDesc(resp.fields.internalName);

                
                const bodyText = resp.fields.bodyText;
                if (bodyText && bodyText.nodeType === 'document' && bodyText.content) {
                    const content = bodyText.content.filter(node => node.nodeType === 'paragraph');
                    if (content.length > 0 && content[0].content.length > 0) {
                        const value = content[0].content[0].value.trim(); 
                        const featureList = value.split(',').map(item => item.trim());
                        setFeatures(featureList);
                    }
                }
            })
            .catch(console.error);
    }, []);

    return (
        <>
            <Heading name={desc} id="offerings"/>
            <div className="offerings-container">
                <ul className={visible ? "features visible" : "features"}>
                    {features.map((feature, index) => (
                        <li key={index} style={{ '--index': index }}>{feature}</li>
                    ))}
                </ul>
            </div>
        </>
    );
}

export default Offerings;
