const Heading =({ name, id }) =>{

    const headStyle = {
        width: '100%',
        backgroundColor: '#302A64',
        color: 'white',
        textAlign: 'center',
        padding: '10px 0',
        fontSize: '24px',
        fontWeight: 'bold',
        borderRadius: '10px',
        fontFamily: '"Recursive", sans-serif',
    };

    return (
        <div style={headStyle} id={id}>
                <span>{name}</span>
        </div>
    );

}
export default Heading;