import React from 'react';
const Resources = () =>{

    return (
        <>
            <h1>Resources</h1>
        </>
    );

}

export default Resources;