import React, { useState, useEffect } from 'react';
import '../css/pricing.css';
import Heading from './heading';
import client from '../client/contentfulClient'; // Import your Contentful client

const Pricing = () => {
    const [plans, setPlans] = useState([]);
    
    useEffect(() => {
        const fetchPlans = async () => {
            const entryIds = ['5r6VmnVwYXWMWXIvOsX3dh', '1qYG2rhrzQiMFjPJsb0noq', '5t3iTVWlygfZjopxmIQLIM'];
            
            const fetchedPlans = await Promise.all(entryIds.map(async (id) => {
                const response = await client.getEntry(id);
                const { internalName, headline, bodyText } = response.fields;

                let features = [];
                if (bodyText && bodyText.nodeType === 'document' && bodyText.content) {
                    const content = bodyText.content.filter(node => node.nodeType === 'paragraph');
                    if (content.length > 0 && content[0].content.length > 0) {
                        const value = content[0].content[0].value.trim();
                        features = value.split(',').map(item => item.trim());
                    }
                }

                return {
                    name: internalName,
                    price: headline,
                    features
                };
            }));

            setPlans(fetchedPlans);
        };

        fetchPlans();
    }, []);

    return (
        <div className="pricing-container" id="pricing">
            <Heading name="Pricing" id="pricing"/>
            {plans.map(plan => (
                <div className="card" key={plan.name}>
                    <div className="content">
                        <div className="title">{plan.name}</div>
                        <div className="price">{plan.price}</div>
                        <ul className="features-list">
                            {plan.features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                    </div>
                    <button><a href="#demo" 
                        style={{textDecoration:'none', color:'white',fontSize:'15px'}}>
                            Get in Touch</a>
                    </button>
                </div>
            ))}
        </div>
    );
}

export default Pricing;
