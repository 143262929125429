import React,{ useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Headers from './components/header';
import MainContent from './components/mainContent';
import Offerings from './components/offerings';
import Pricing from './components/pricing';
import Demo from './components/demo';
import Footer from './components/footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Resources from './components/resources';

function App() {
  const [navOpen, setNavOpen] = useState(false);
  return (
    <Router>
            <div className="App">
                <Headers setNavOpen={setNavOpen} navOpen={navOpen} />
                <div className={navOpen ? "content-shift" : ""}>
                    <Routes>
                        <Route path="/resources" element={<Resources />} />
                        <Route path="/" element={
                            <>
                                <MainContent />
                                <Offerings />
                                <Pricing />
                                <Demo />
                                <Footer />
                            </>
                        } />
                    </Routes>
                </div>
            </div>
        </Router>
  );
}

export default App;
