import '../css/mainContent.css';  
import mainImage from '../images/mainImage1.png';
const MainContent =()=>{

    return (
        <div className="content-container">
            <div className="text-side">
                <h1>Optimize your restaurant's operations effortlessly with our advanced POS software!</h1> 
                <h4>Concentrate on enhancing your brand and achieving your business goals.</h4>
                {/* <p>Discover the best dining experience with our advanced CRM tools that help manage your restaurant efficiently and enhance customer satisfaction.</p> */}
            </div>
            <div className="image-side">
                <img src={mainImage} alt="Dining Experience" />
            </div>
            
        </div>
    );

}

export default MainContent;