import React, { useState } from 'react';
import '../css/header.css';  
import logoImage from '../images/logo.png';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Resources from './resources';

const Navbar = ({ setNavOpen, navOpen }) => {
    return (
        <div className="navbar">
            <div className="logo">
                <Link to="/" style={{textDecoration:'none'}}>
                    {/* <img src={logoImage} alt="KafeNeo Logo" style={{ borderRadius: '10px', height: '30px', marginRight: '5px', verticalAlign: 'middle' }} /> */}
                    <span>KafeNeo</span>
                </Link>
            </div>
            <div className='container'>
                <ul className={navOpen ? "nav-links show-nav" : "nav-links"}>
                    <li><a href="#offerings">Features</a></li>
                    <li><a href="#pricing">Pricing</a></li>
                    {/* <li><Link to="/resources">Resources</Link></li> */}
                    <li><a href="#demo">Demo</a></li>
                    <li><a href="#footer">Support</a></li>
                </ul>
                <button className="nav-toggle" onClick={() => setNavOpen(!navOpen)}>
                    <p className="bar"></p>
                    <p className="bar"></p>
                    <p className="bar"></p>
                </button>
            </div>
        </div>
    );
}

export default Navbar;
