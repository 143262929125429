import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../css/demo.css';
import queryImage from '../images/customerService.png';
import Heading from './heading';

const Demo = () => {
    const form = useRef();
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({
        FullName: '',
        Email: '',
        Phone: '',
        Address: '',
        RestaurantName: ''
    });

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_gtcqana', 'template_52pqcua', form.current, 'aQ45s9vw2QXMax4jE')
            .then(() => {
                console.log('SUCCESS!');
                setShowPopup(true);
                setTimeout(() => setShowPopup(false), 2000);
                setFormData({
                    FullName: '',
                    Email: '',
                    Phone: '',
                    Address: '',
                    RestaurantName: ''
                });
            }, (error) => {
                console.log('FAILED...', error.text);
            });

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <Heading name="Take a Free Demo" id="demo" />
            <div className="demo-content">
                <h4>Connect with our expert team to swiftly address your inquiries.</h4>
                <div className="form-container">
                    <form ref={form} onSubmit={sendEmail}>
                        <input
                            type="text"
                            name="FullName"
                            placeholder="Full Name"
                            value={formData.FullName}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="email"
                            name="Email"
                            placeholder="Email"
                            value={formData.Email}
                            onChange={handleChange}
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            title="Please enter a valid email address"
                            required
                        />
                        <input
                            type="tel"
                            name="Phone"
                            placeholder="Phone"
                            value={formData.Phone}
                            onChange={handleChange}
                            pattern="\d{10}"
                            title="Please enter a 10-digit phone number"
                            required
                        />
                        <input
                            type="text"
                            name="Address"
                            placeholder="Address"
                            value={formData.Address}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="RestaurantName"
                            placeholder="Restaurant Name"
                            value={formData.RestaurantName}
                            onChange={handleChange}
                            required
                        />
                        <button type="submit">Send</button>
                    </form>
                </div>
                <div className="image-container">
                    <img src={queryImage} alt="Query Resolution" />
                </div>
            </div>
            {showPopup && (
                <div className="popup">
                    Data sent successfully!
                </div>
            )}
        </>
    );
}

export default Demo;
