import React from 'react';
import '../css/footer.css';  
import { BrowserRouter as Router, Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer" id='footer'>
            <div className="footer-section">
                <h4>Services</h4>
                <ul>
                    <li><a href="/">Order Management</a></li>
                    <li><a href="/about">Billing System</a></li>
                    <li><a href="/services">Kitchen Display System</a></li>
                    <li><a href="/blog">Scan and Order</a></li>
                </ul>
            </div>
            <div className="footer-section">
                <h4>Resources</h4>
                <ul>
                    {/* <li><Link to="/resources">Videos</Link></li>
                    <li><Link to="/resources">Blogs</Link></li>
                    <li><Link to="/resources">News</Link></li> */}
                    <li>Videos</li>
                    <li>Blogs</li>
                    <li>News</li>
                </ul>
            </div>
            <div className="footer-section">
                <h4>Send Your Query</h4>
                <p>kafeneotech09@gmail.com</p>
            </div>
            <div className="footer-bottom">
                <p>© 2024 KafeNeo. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
